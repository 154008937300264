<template>
  <article>
    <div class="relative block">
      <div class="w-full overflow-hidden rounded-xl bg-white">
        <NuxtLink
          :to="to"
          :class="['block text-black ', { 'p-4': image.src && imageMargin }]">
          <NuxtImg
            v-show="loaded || cover?.$el.complete || props.logo?.src"
            format="webp"
            fit="contain"
            sizes="sm:100vw md:400px lg:800px"
            ref="cover"
            :src="image.src || props.logo?.src || useImagePlaceholder('square')"
            :alt="image.alt"
            :provider="(image.src || props.logo?.src) && image.provider"
            width="50"
            height="50"
            class="aspect-square w-full bg-white object-contain object-center px-0"
            @load="loaded = true"
            :class="[
              loaded || cover?.$el.complete || props.logo?.src
                ? ''
                : 'animate-pulse rounded-lg bg-neutral-600'
            ]" />
          <!-- <div v-if="!(loaded || cover?.$el.complete)" role="status"
            class="aspect-square w-full "></div> -->
        </NuxtLink>
        <SquareButton
          v-if="showPlayButton"
          icon="play-fill"
          class="absolute bottom-2.5 right-2 text-2xl"
          :aria-label="$t('ColumnCard.play')"
          @click="emit('play')" />
      </div>

      <!-- logo at the bottom left of the card -->
      <div v-if="props.logo?.src">
        <div
          v-show="logoLoaded || logo?.$el.complete"
          class="absolute bottom-0 left-0 w-8 -translate-x-1/4 translate-y-1/4 transform rounded-lg bg-white p-1">
          <NuxtImg
            format="webp"
            sizes="sm:100vw md:400px lg:800px"
            ref="logo"
            :src="props.logo?.src || useImagePlaceholder('square')"
            :alt="props.logo?.alt"
            :provider="props.logo.src && props.logo.provider"
            width="50"
            height="50"
            class="aspect-square w-full object-contain object-center"
            @load="logoLoaded = true" />
        </div>
      </div>
    </div>
    <div class="w-full py-5 pb-2">
      <NuxtLink :to="to">
        <h3
          :class="[
            'mb-1 w-full overflow-hidden text-ellipsis whitespace-nowrap text-left font-bold text-neutral-400',
            textSize
          ]">
          {{ title }}
        </h3>
        <div
          v-if="description"
          class="mb-2 overflow-hidden text-ellipsis text-sm text-neutral-500">
          {{
            truncate(useHTMLCleaner(description), {
              length: 50,
              separator: '...'
            })
          }}
        </div>
        <div
          v-if="category"
          class="text-sm text-neutral-500">
          {{ category }}
        </div>
      </NuxtLink>
    </div>
  </article>
</template>

<script lang="ts" setup>
import _ from 'lodash'

const props = withDefaults(
  defineProps<{
    image: Image
    title?: string
    category?: string
    description?: string
    to?: string
    textSize?: string
    imageMargin?: boolean
    showPlayButton?: boolean
    logo?: Image
  }>(),
  {
    textSize: 'text-xl',
    imageMargin: false,
    showPlayButton: true
  }
)

const truncate = _.truncate

const emit = defineEmits(['play'])

// cover image loading state
const loaded = ref(false)
const cover = ref()

// logo loading state
const logoLoaded = ref(false)
const logo = ref()
</script>
