import he from 'he'

// decode html entites and remove html tags
export const useHTMLCleaner = (
  text: string,
  keepParagraphs: boolean = false
) => {
  let cleaned = he.decode(text)

  if (keepParagraphs) {
    cleaned = cleaned
      .replace(/(<\/?(?:p|br)[^>]*>)|<[^>]+>/gi, '$1') // remove all tags except p & br
      .replace(/(<[^>]+) style=".*?"/gi, '$1') // remove style attributes
  } else {
    cleaned = cleaned.replace(/(<([^>]+)>)/gi, '') // remove all tags
  }

  return cleaned
}
